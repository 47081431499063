import { useEffect } from 'react';

const useSetBodyClass = (className = '') => {
  useEffect(() => {
    if (typeof window !== 'undefined' && className) {
      document.querySelector('body').classList.add(className);
      return () => {
        document.querySelector('body').classList.remove(className);
      };
    }
  }, [className]);
};

export default useSetBodyClass;
